<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle">
            <i class="icon icon-grid"></i>
            <span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header v-show="categoryMode" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                <p v-if="!categoryMode" class="title">Расшифровка. {{ currCategory.name_ru }}</p>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <template v-if="categoryMode">
                        <c-budget-forms-copy-data
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            @keyPress="keyPress"
                        />
                        <b-button variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> Добавить</b-button>
                    </template>
                    <template v-else>
                        <b-button variant="light" @click="resetModal">
                           <i class="icon icon-keyboard icon-rotate-180"></i> Назад к списку
                        </b-button>
                        <b-button v-if="variantAttribute" variant="primary" @click="$refs.formTemplate.addItem()">
                            <i class="icon icon-plus-circle"></i>Добавить
                        </b-button>
                        <b-button
                            :disabled="!dataChanged || goodsAreSaving"
                            variant="success"
                            @click="prepareForSave">
                            Сохранить
                        </b-button>
                    </template>
                </div>
            </div>
        </div>

        <breadcrumbs-filter v-show="categoryMode" :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div v-show="categoryMode" class="table-container" >
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering text-center"></td>
                    <td class="td-numbering text-center"></td>
                    <td class="td-numbering text-center table-success">1</td>
                    <td class="td-numbering text-center table-danger">2</td>
                    <td class="td-numbering text-center table-info">3</td>
                    <td class="td-numbering text-center table-primary">4</td>
                    <td class="td-numbering text-center table-warning">5</td>
                    <td class="td-numbering text-center table-success">6</td>
                    <td class="td-numbering text-center"></td>
                </template>
                <template #head(selection)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            title="Удалить выбранные записи"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? 'всех' : 'выбранных'} категорий`)"/>
                    </div>
                </template>
                <template #cell(selection)="data">
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="data.item.itemToDelete"
                            @input="e => { if (!e) selectAll = false; }"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #cell(action)="data">
                    <button
                        class="table-plus"
                      
                        @click="setCurrCatName(data.item.good_type, data.item)"
                        :disabled="!data.item.categoryCode"
                    >
                        <i class="icon icon-plus-circle"/>
                    </button>
                </template>
                <template #cell(good_type)="data">
                    <div class="column-width-100">
                        <multiselect
                                v-if="variantAttribute && data.item.newCat"
                                v-model="data.item.good_type"
                                label="name_ru"
                                placeholder="Выбрать категорию"
                                :options="dict"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="changeValue(data.item, data.item.good_type, data)"
                            >
                        </multiselect>
                        <div v-else>{{ data.item.good_type.name_ru }}</div>
                    </div>
                </template>
                <template #cell(unit)="data">
                    <div>{{ data.value }}</div>
                </template>
                <template #cell(amount)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(price)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onAllFilesClick({ ...data.item, name_ru: data.item.good_type.name_ru})"
                    >({{data.item.files ? data.item.files.length : 0}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i class="icon icon-clear table-remove" v-if="variantAttribute" :disabled="deletingCat" @click="deleteItem('данной категории', data.item, data.index, true)"></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="6">ИТОГО</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
            <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
                <span @click="addItem"><i class="icon icon-plus-circle"></i> Добавить</span>
            </div>
        </div>

        <template v-if="!categoryMode">
            <b-overlay :show="templateTableOverlay" rounded="sm">
                <form-03149-template1
                    ref="formTemplate"
                    :formCode="form.code"
                    :goodsData="goodsData"
                    :initialGoodsData="initialGoodsData"
                    :ensTruDict="ensTruDict"
                    :variantAttribute="variantAttribute"
                    :currCategory="currCategory"
                    :lang="lang"
                    :ifAllFieldsFilled="ifAllFieldsFilled"
                    :deleteGood="deleteGood"
                    :getUnitName="getUnitName"
                    :ensTruNamePrt="ensTruNamePrt"
                    :ensTruName="ensTruName"
                    :currUnits="currUnits"
                    :descIsLoading="descIsLoading"
                    :parNameLoading="parNameLoading"
                    :allFiles="allFiles"
                    @setTotals="setTotals"
                    @updateDatas="updateDatas"
                    @save="save"
                    @onDataChanged="onDataChanged"
                    @searchName="searchName"
                    @setIsAllDelete="setIsAllDelete"
                    @deleteSeveralGoods="deleteSeveralGoods"
                    :triggDownloadFile="triggDownloadFile"
                    :showFileUploadModal="showFileUploadModal"
                    :getEnstruByName="getEnstruByName"
                    :loadCurUnits="loadCurUnits"
                    :goodsAreSaving="goodsAreSaving"
                ></form-03149-template1>
                <div class="table-add" v-if="variantAttribute">
                    <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i> Добавить</span>
                </div>
            </b-overlay>
        </template>
        <files-updown ref="filesUpdown"
            v-show="categoryMode"
            :header="header"
            :variant-attribute="variantAttribute"
            :load="load"
            :showDeleteLinks="variantAttribute"
            :showDeleteAll="true"
            @getFiles="getFiles"
            @fillFilesList="fillFilesList"
            @restetFilesList="restetFilesList"
            @delelteAttachedFile="delelteAttachedFile"
        ></files-updown>
        <modal-all-files-management
            ref="modalAllFilesManagement"
            :allFiles="allFiles"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            >
        </modal-all-files-management>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
// import { Ax } from '@/utils';
// import BudgetAttachFile from "./components/budget-attach-file";
import Form03149Template1 from "./components/budget-forms-template1.vue";
import FormsHandlerMixin from "./mixins/forms-handler-mixin";
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form03_149',
    components: { BudgetHeader,
                    FilesUpdown,
                    BudgetFormsList,
                    FormsDownloadReprt,
                    BreadcrumbsFilter,
                    Form03149Template1,
                    ModalAllFilesManagement,
                    CBudgetFormsCopyData
                },
    mixins: [FormsHandlerMixin],
    data() {
        return {
            form: {
                code: '03-149',
                name_ru: 'Расчет расходов по приобретению товаров, необходимых для обслуживания и содержания основных средств, строительных материалов, используемых на ремонт основных средств, запасных частей для оборудования, транспортных средств и других запасов, непосредственно связанных с содержанием, обслуживанием и ремонтом',
                name_kk: 'Негізгі құралдарды ұстау және қызмет көрсету үшін қажет тауарларды, негізгі құралдарды жөндеуге пайдаланылатын құрылыс материалдарын, жабдықтар, көлік құралдары үшін қосалқы бөлшектер және ұстаумен, қызмет көрсетумен және жөндеумен тікелей байланысты басқа қорларды сатып алу бойынша шығыстардын есебіу'
            },
            tableFields: [
                {
                    key: 'selection',
                    label: ' '
                },
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'good_type',
                    label: 'Наименование'
                },
                {
                    key: 'unit',
                    label: 'Ед.изм.'
                },
                {
                    key: 'amount',
                    label: 'Кол-во'
                },
                {
                    key: 'price',
                    label: 'Средняя стоимость за единицу, тенге'
                },
                {
                    key: 'total',
                    label: 'Общая стоимость, тыс.тенге (гр.3 х гр. 4)/1000'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            isReportUploading: false,
            modalMaxWidth: '1300px',
        };
    },
    mounted() {
        this.progress = 15;
    },

    methods: {
        addItem() {
            if (this.budgetForm.findIndex(item => item.id === -1) !== -1) return;
            const item = { id: -1 };
            this.itemUpdate(item);
            this.budgetForm.push(item);
        },

        changeValue(item, value, data) {
            this.$set(item, 'unit', value.unit);
            this.$set(item, 'categoryCode', value.code);
            this.disableDictItms();
        },

        disableDictItms() {
            this.dict.forEach(dictItm => {
                if (this.budgetForm.findIndex(frm => frm.categoryCode === dictItm.code) !== -1) {
                    this.$set(dictItm, '$isDisabled', true);
                } else {
                    this.$set(dictItm, '$isDisabled', false);
                };
            });
        },

        getItem(code, list) {
            for (const item of list) {
                if (item.code === code) {
                    return item;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка


        itemUpdate(item) {
            this.$set(item, 'categoryCode', null);
            this.$set(item, 'good_type', null);
            this.$set(item, 'amount', 0);
            this.$set(item, 'price', 0);
            this.$set(item, 'unit', null);
            this.$set(item, 'total', 0);
            this.$set(item, 'newCat', true);
            this.$set(item, 'itemToDelete', false);
        },

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        async loadCategoryData() {
            this.load = true;
            const newBudgetForm = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat/' + JSON.stringify(this.header));
                    if (response.status === 200) {
                        const values = await response.json();
                        values.forEach((val, i) => {
                            const item = {
                                id: i,
                                categoryCode: val.good_type,
                                good_type: this.getItem(val.good_type, this.dict),
                                amount: val.sum_amount,
                                price: parseFloat(val.avg_price.toFixed(2)),
                                total: parseFloat((val.sum_total / 1000).toFixed(3)),
                                files: this.getUnicFilesArr(val),
                                newCat: false,
                                itemToDelete: false,
                            };
                            this.$set(item, 'unit', item.good_type.unit);
                            newBudgetForm.push(item);
                        });
                        this.budgetForm = newBudgetForm.sort((a, b) => a.id - b.id);
                        this.disableDictItms();
                    }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCategoryData()', error.toString());
            }
            this.load = false;
        },



        noAbc: function (event) {
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит только цифры

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        },
    }
};
</script>

<style scoped>
#goods-modal-header-i {
    cursor: pointer;
}
.filter-actions-flex {
    display: flex;
}
.table-plus {
    border: none;
}
</style>